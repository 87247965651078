
.login-page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgb(28,34,96);
  background: linear-gradient(332deg, rgba(28,34,96,1) 0%, rgba(3,5,31,1) 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-logo {
  width: 300px;
  height: 100px;
  background: url('./static/scc-logo.png') center center no-repeat;
  background-size: contain;
  margin-bottom: 50px;
}

.menu {
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.menu div {
  display: inline-block;
  margin: 5px 10px;
  transition: .2s;
}
.menu a {
  text-decoration: none;
  font-weight: bold;
  color: rgb(28, 34, 96);
  transition: .2s;
}
.menu a:hover {
  color: rgba(28, 34, 96, 0.6);
}
.menu div.currentLink {
  border-bottom: 2px solid rgb(88, 98, 204);
}
.menu div.currentLink a {
  color: rgb(88, 98, 204);
}



.scheduler > tbody {
  background: #fff;
}

.react-confirm-alert-body h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
}


/* FlatPickr */
.flatpickr-input {
  font-size:1.6rem;
  font-weight:400;
  line-height:2.4rem;
  text-transform:initial;
  letter-spacing:initial;
  position:relative;
  z-index:20;
  display:block;
  -webkit-box-flex:1;
      -ms-flex:1 1 0%;
          flex:1 1 0%;
  width:100%;
  min-width:0;
  min-height:3.6rem;
  margin:0;
  padding:0.5rem 1.2rem;
  background:none;
  border:0.1rem solid #c4cdd5;
  font-family:inherit;
  font-size:inherit;
  font-weight:inherit;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  outline: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
}

.flatpickr-input:focus {
  border-color: #5c6ac4;
  box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px #5c6ac4;
}